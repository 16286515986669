/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable prettier/prettier */
import { useContext } from 'react';
import { FaPen } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NumberFormat } from '../../../../../components/NumberFormat';
import { CheckoutContext } from '../../../../../contexts/CheckoutContext';
import { CompanyContext } from '../../../../../contexts/CompanyContext';
import { ModalReviewContext } from '../../../../../contexts/ModalReviewContext';
import { NumberContext } from '../../../../../contexts/NumberContext';
import api from '../../../../../services/api';
import { IDataDesconto } from '../../ModalDiscont';
import { Container, CupomArea, ValueContainer, ValuesContainer } from './styles';

export function Footer(

): JSX.Element {
  const { changeIsModalReviewVisible, changeIsmodalDiscontVisible } =
    useContext(ModalReviewContext);
  const { orderPrice, createOrder, lastOrder } = useContext(CheckoutContext);
  const { dataDesconto, cupom, cpf, setCupom, setCpf, setDataDesconto } = useContext(NumberContext);
  const {
    setIsLoading,
    loadPaymentMethods,
    needPayment,
    requestNumberMobile,
    selectedCompany,
    setIsMsgBox,
    setOpenIsMsgBox,
    isAdminEmpresa,
    serviceElectron,
    isLoading
  } = useContext(CompanyContext);

  const history = useHistory();

  async function handleConfirm() {
    changeIsModalReviewVisible(false);
    setIsLoading(true)
    try {
      const resp: any = await api.get(
        `api/v1/Empresa/GetEmpresaAtivaById/${selectedCompany.id}`,
        {
          withCredentials: true,
        },
      );
      const respCompany = resp.data;
      if (!respCompany[0].aberto && !isAdminEmpresa) {
        setOpenIsMsgBox(true);
        setIsMsgBox('Empresa Fechou, tente novamente mais tarde.');
        history.push('/');
        return;
      }

      if (selectedCompany.solicitaEmailCpf)
        history.push('/orderNF');
      else if (requestNumberMobile && selectedCompany.solicitaTelefone)
        history.push('/orderConfirmation');

      // else if ([1, 4].includes(selectedCompany.typeDelivery)) {
      //   history.push('/chooseDelivery')
      // }
      else if (needPayment) {
        serviceElectron?.ipcRenderer?.invoke('Log', `Solicita pagamento`);
        await loadPaymentMethods();
        history.push('/payment');
      } else {
        serviceElectron?.ipcRenderer?.invoke('Log', `Not pagamento`);
        try {
          await createOrder(0);
        } catch (error) {
          serviceElectron?.ipcRenderer?.invoke(
            'LogError',
            `ModalReview ${error}`,
          );
        } finally {
          if (Object.keys(lastOrder).length !== 0) history.push('/success');;
        }
      }
    } catch (error) {
      if (!navigator.onLine) history.push('/');
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleDeleteCupom = () => {
    setCupom('')
    setCpf('')
    toast.success('Cupom deletado com sucesso!')
    setDataDesconto({} as IDataDesconto)
  }

  return (
    <Container>
      <div>
        <button
          type="button"
          className="cupomButton"
          onClick={() => changeIsmodalDiscontVisible(true)}
          style={{
            display: Object.keys(dataDesconto).length === 0 ? 'block':'none',
            marginBottom: Object.keys(dataDesconto).length === 0 ? '1rem':'0',
          }}
          disabled={isLoading}
        >
          Adicionar cupom de desconto
        </button>


        <CupomArea
          style={{
          display: !cupom ? 'none' : 'flex',
        }}
        >
          <span>
            <span>Cupom: </span> {cupom}
          </span>
          <div id='containerButtonDiscont'>
            <button
              id='discont'
              type='button'
              onClick={() => {
                localStorage.setItem('cupom_storage', cupom)
                localStorage.setItem('cpf_storage', cpf)
                changeIsmodalDiscontVisible(true)
              }}
              disabled={isLoading}
            >
              <FaPen />
            </button>
            <button
              id='discont'
              type='button'
              onClick={handleDeleteCupom}
              disabled={isLoading}
            >
              <IoMdClose />
            </button>
          </div>
        </CupomArea>


      </div>
      <ValuesContainer>
        <ValueContainer
          isTotal={Object.keys(dataDesconto).length ===0}
        >
          <strong>Total</strong>
          <NumberFormat prefix="R$">{orderPrice}</NumberFormat>
        </ValueContainer>
        <ValueContainer
          style={{
            display: Object.keys(dataDesconto).length ===0? 'none':'flex',
          }}
        >
          <strong>Desconto</strong>
          <NumberFormat prefix="R$">
            {dataDesconto.tipoValor ===0?
              (orderPrice*(dataDesconto.valorDesconto/100))
              : dataDesconto.valorDesconto}
          </NumberFormat>
        </ValueContainer>
        <ValueContainer
          isTotal={Object.keys(dataDesconto).length >0}
          style={{
            display: Object.keys(dataDesconto).length ===0? 'none':'flex',
          }}
        >
          <strong>Total com Desconto</strong>
          <NumberFormat prefix="R$">
            {orderPrice-(dataDesconto.tipoValor ===0?
              orderPrice*((dataDesconto.valorDesconto/100))
              : dataDesconto.valorDesconto)}
          </NumberFormat>
        </ValueContainer>
      </ValuesContainer>
      <div>
        <button type="button" onClick={() => changeIsModalReviewVisible(false)} disabled={isLoading}>
          Voltar
        </button>
        <button type="button" onClick={() => handleConfirm()} disabled={isLoading}>
          Confirmar
        </button>
      </div>
    </Container>
  );
}
