import { useContext } from 'react';
import { TbRating18Plus } from 'react-icons/tb';
import { LuMilk, LuWheat } from 'react-icons/lu';
import { RiPlantLine } from 'react-icons/ri';
import EmptyProduct from '../../../assets/empty-product.png';
import { NumberFormat } from '../../../components/NumberFormat';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { ProductContext } from '../../../contexts/ProductContext';
import { CreditItem } from '../CreditItem';
import { AdultProductIcon, Item, List } from './styles';

export function ProductItemList(): JSX.Element {
  const { selectedCompany, isAdminEmpresa } = useContext(CompanyContext);
  const { products, selectProduct } = useContext(ProductContext);
  return (
    <List>
      {products.map((product) =>
        product.geraValorEmCredito ? (
          <CreditItem
            key={product.idProduto}
            product={product}
            selectProduct={selectProduct}
          />
        ) : (
          <Item
            key={product.idProduto}
            readOnlyMenu={selectedCompany.id === 8}
            isTitleGrand={product.nome.length > 30}
          >
            <button
              type="button"
              onClick={() => selectProduct(product.idProduto)}
              disabled={
                isAdminEmpresa
                  ? false
                  : !product.ativo || !selectedCompany.aberto
              }
            >
              <aside>
                <AdultProductIcon hidden={!product?.maiorDeIdade}>
                  <TbRating18Plus size={40} />
                </AdultProductIcon>
                <img
                  src={product.imagem !== '' ? product.imagem : EmptyProduct}
                  alt={product.nome}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = EmptyProduct;
                  }}
                />
              </aside>
              <div>
                <strong>{product.nome}</strong>
                {product.descricao !== '' && product.nome.length <= 30 && (
                  <span>{product.descricao}</span>
                )}
                <div className="info-icons-area">
                  {product?.semLactose && (
                    <span className="info-icons">
                      <LuMilk />
                    </span>
                  )}
                  {product?.vegano && (
                    <span className="info-icons vegan">
                      <RiPlantLine style={{ color: '#6F8C04' }} />
                    </span>
                  )}
                  {product?.semGluten && (
                    <span className="info-icons wheat">
                      <LuWheat style={{ color: '#D9C14A' }} />
                    </span>
                  )}
                </div>
                {product.preco !== 0 && (
                  <div>
                    {!product.precoFixo && <small>A partir de</small>}
                    <div className="valores">
                      <NumberFormat prefix="R$" className="valorPromocional">
                        {!product?.valorPromocional
                          ? product.preco
                          : product.valorPromocional}
                      </NumberFormat>
                      <NumberFormat
                        prefix="R$"
                        className="preco"
                        hidden={!product?.valorPromocional}
                      >
                        {product.preco}
                      </NumberFormat>
                    </div>
                  </div>
                )}
              </div>
              <section id="indisponivel">
                <div>INDISPONÍVEL</div>
              </section>
            </button>
          </Item>
        ),
      )}
    </List>
  );
}
