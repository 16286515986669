/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import api from '../services/api';
import { CompanyContext } from './CompanyContext';
import { MenuListContext } from './MenuListContext';
import { SubgroupContext } from './SubgroupContext';

interface ProductContextData {
  products: Product[];
  selectedProduct: Product;
  selectProduct: (id: number) => void;
}

interface ProductProviderProps {
  children: ReactNode;
}

interface Product {
  idProduto: number;
  nome: string;
  descricao: string;
  imagem: string;
  preco: number;
  precoFixo: boolean;
  valorPromocional: number;
  geraValorEmCredito: boolean;
  exibirObservacao: boolean;
  ativo: boolean;
  grupoId: number;
  subGrupoId: number;
  maiorDeIdade: boolean;
  semGluten: boolean;
  semLactose: boolean;
  vegano: boolean;
}

export const ProductContext = createContext({} as ProductContextData);

export function ProductProvider({
  children,
}: ProductProviderProps): JSX.Element {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState({} as Product);

  const { selectedCompany, isOrderFinalized, setIsLoading, baseUrl, serviceElectron } =
    useContext(CompanyContext);
  const { selectedGroup } = useContext(MenuListContext);
  const { selectedSubgroup } = useContext(SubgroupContext);

  useEffect(() => {
    async function loadContent() {
      try {
        setIsLoading(true);
        setProducts([]);
        const response = await api.get(`api/v1/ProdutoGrupoSubGrupo/GetAllProdutoCardapio/${selectedCompany.id}`,{
          params:{
            grupoId: selectedGroup.idGrupo
          }
        });
        const sortedProducts = response.data.sort((a: any, b: any) => {
          return b.ativo - a.ativo;
        });
        // eslint-disable-next-line no-restricted-syntax
        for (const produto of response.data) {
          const index = sortedProducts.findIndex(
            (x: any) => x.idProduto === produto.idProduto,
          );
          if (produto.imagem !== null && produto.imagem !== '') {
            let logo =  `${baseUrl}${produto.imagem}`
            if (serviceElectron?.isElectronApp) {
              // eslint-disable-next-line no-await-in-loop
              const responseSmart = await serviceElectron?.ipcRenderer?.invoke(
                'getImage',
                produto.imagem,
                false,
                '',
              );
              if (responseSmart.Sucesso) logo = responseSmart.Msg;
            }
            sortedProducts[index].imagem = logo;
          }

          sortedProducts[index].grupoId = selectedGroup.idGrupo;
          sortedProducts[index].subGrupoId = 0;
        }
        setProducts(sortedProducts.sort((a:any, b:any) => a?.ordem === null ? 1 : b?.ordem === null ? -1 : a?.ordem - b?.ordem));
      } catch (error) {
        console.log(error);
      }finally{
        setIsLoading(false);
      }
    }

    if (Object.keys(selectedGroup).length === 0) return;
    loadContent();
  }, [selectedCompany, selectedGroup, selectedSubgroup, setIsLoading]);

  useEffect(() => {
    if (!isOrderFinalized) return;

    setProducts([]);
    setSelectedProduct({} as Product);
  }, [isOrderFinalized]);

  function selectProduct(id: number) {
    const selected = products.find((product) => product.idProduto === id);
    setSelectedProduct(selected || ({} as Product));
  }

  return (
    <ProductContext.Provider
      value={{
        products,
        selectedProduct,
        selectProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}
