/* eslint-disable consistent-return */
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Arrow from '../../assets/animations/arrow.json';
import Logo from '../../assets/logo.png';
import { CheckoutContext } from '../../contexts/CheckoutContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { ModalComprovante } from './Modais/ModalComprovante';
import { ModalMensageError } from './Modais/ModalMensageError';
import { Container } from './styles';

declare global {
  interface Window {
    bound: any;
  }
}

window.bound = window.bound || {};

const optionsArrow = {
  loop: true,
  autoplay: true,
  animationData: Arrow,
  renderer: 'svg',
};

export function OrderSucceeded(): JSX.Element {
  const { selectedCompany, needPayment, serviceElectron } =
    useContext(CompanyContext);
  const { lastOrder } = useContext(CheckoutContext);
  const history = useHistory();
  const [errorCompr, setErroCompr] = useState(false);
  const [intervalId, setIntervalId] = useState<number>();

  const [isOpenComprovante, setIsOpenComprovante] = useState<boolean>(false);
  const theme = useTheme();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const goBack = () =>
    setIntervalId(setTimeout(() => history.push('/'), 1000 * 20));

  const print = async () => {
    try {
      let imprimiu = '';
      if (serviceElectron) {
        imprimiu = await serviceElectron?.ipcRenderer?.invoke(
          'imprimir-resumo-pedido',
          { Empresa: selectedCompany.id, IdPedido: lastOrder.idPedido },
        );
        if (
          selectedCompany.habilitarNFCe &&
          localStorage.getItem('xml') !== null
        ) {
          try {
            await serviceElectron?.ipcRenderer?.invoke('imprimir-NFCe-pedido', {
              Empresa: selectedCompany.id,
              IdPedido: lastOrder.idPedido,
              xml: localStorage.getItem('xml') || '',
            });
          } catch (error) {
            console.log('imprimir-NFCe', error);
          }
          localStorage.removeItem('xml');
        }
      }

      if (imprimiu === 'Sem Papel') {
        if (intervalId !== undefined) clearTimeout(intervalId);
        setIsOpenComprovante(false);
        setErroCompr(true);
      } else if (imprimiu !== 'OK') {
        if (intervalId !== undefined) clearTimeout(intervalId);
        setIsOpenComprovante(false);
        setErroCompr(true);
      }
    } catch (error) {
      setErroCompr(true);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const printReceipt = async () => {
    try {
      let imprimiu = '';
      if (serviceElectron?.isElectronApp) {
        imprimiu = await serviceElectron?.ipcRenderer?.invoke(
          'imprimir-comprovante-pagamento-pedido',
          {
            Empresa: selectedCompany.id,
            IdPedido: lastOrder.idPedido,
          },
        );

        if (imprimiu === 'Sem Papel') {
          if (intervalId !== undefined) clearTimeout(intervalId);
          setIsOpenComprovante(false);
          setErroCompr(true);
        } else if (imprimiu !== 'OK') {
          if (intervalId !== undefined) clearTimeout(intervalId);
          setIsOpenComprovante(false);
          setErroCompr(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(lastOrder).length > 0 || serviceElectron?.isElectronApp) {
      print();
      if (needPayment && !lastOrder.urlPagamentoPIX) setIsOpenComprovante(true);
    }

    goBack();
    return () => {
      if (intervalId !== undefined) clearTimeout(intervalId);
    };
  }, [lastOrder]);

  const printerComprovante = async () => {
    if (intervalId !== undefined) clearTimeout(intervalId);
    if (Object.keys(lastOrder).length > 0 && serviceElectron?.isElectronApp)
      await printReceipt();
    goBack();
  };

  return (
    <Container>
      <ModalMensageError
        isOpen={errorCompr}
        senha={lastOrder.numeroIdentificador}
        empresa={selectedCompany.id}
        idPedido={lastOrder.idPedido}
        isNotPaper
      />
      <ModalComprovante
        isOpen={isOpenComprovante}
        imprimir={() => {
          printerComprovante();
          setIsOpenComprovante(false);
        }}
        notimprimir={() => setIsOpenComprovante(false)}
      />
      <section>
        <img
          src={
            Object.keys(selectedCompany).length !== 0
              ? selectedCompany.logotipo
              : Logo
          }
          alt="Logotipo"
        />
      </section>
      {!needPayment ? (
        <>
          <h1
            style={{
              color: theme.colors.DefaultColor,
              display: serviceElectron?.isElectronApp ? 'none' : 'block',
            }}
          >
            Por favor, aguarde a impressão total do pedido!
          </h1>
          <h1
            style={{
              color: theme.colors.DefaultColor,
              display: serviceElectron?.isElectronApp ? 'block' : 'none',
            }}
          >
            Por favor, imprima a senha do pedido!
          </h1>
        </>
      ) : (
        <>
          <h1>Pedido realizado com sucesso!</h1>
          <h1 style={{ color: theme.colors.DefaultColor }}>
            Por favor, aguarde a impressão total do pedido!
          </h1>
        </>
      )}
      <strong>O número do seu pedido é:</strong>
      <p>{lastOrder.numeroIdentificador}</p>
      {/*  <Lottie speed={1.5} options={optionsArrow} width="15rem" /> */}
    </Container>
  );
}
